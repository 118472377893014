@charset "utf-8";
/*============================================

  CARD

============================================*/
/*-------------------------------
  CARD > INTERVIEW
-------------------------------*/
.c-card_interview{
  &__link{
    display: block;
    @include mq(sm-){
      &:hover{
        opacity: .5;
      }
    }
  }
  &__img{
    position: relative;
    font-size: 3.2em;
    padding-top: .5em;
    line-height: 1;
    img{
      position: relative;
      width: 100%;
      z-index: 1;
    }
    span{
      position: absolute;
      left: 0;
      top: 0;
      font-weight: 900;
      color: $main_color;
      z-index: 2;
    }
  }
  &__txt{
    margin-top: 1em;
  }
  &__desc{
    font-size: .85em;
    font-weight: 700;
    color: $main_color;
    line-height: 1.25;
    margin-bottom: 1.75em;
  }
  &__ttl{
    font-size: 1.6em;
    line-height: 1;
    font-weight: 700;
    >*{
      display: block;
    }
    span{
      font-size: .5em;
      margin-bottom: 1em;
    }
    em{
      font-style: normal;
    }
  }
  @include mq(md){
    &__desc{
      font-size: .875em;
    }
    &__ttl{
      font-size: 1.4em;
      span{
        font-size: .625em;
      }
    }
  }
  @include mq(ss-){
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    > *{
      width: 25%;
    }
  }
  @include mq(sm){
    &__desc{
      font-size: 1em;
      margin-bottom: 1em;
    }
    &__ttl{
      font-size: 1.6em;
    }
    &__img{
      font-size: 2.8em;
    }
    @include mq(ss-){
      > *{
        width: 48%;
        &:nth-child(n+3){
          margin-top: $margin_md;
        }
      }
    }
  }
  @include mq(ss){
    > *{
      &:nth-child(n+2){
        margin-top: $margin_md;
      }
    }
    &__ttl{
      span{
        margin-bottom: .5em;
      }
    }
  }
}
/*-------------------------------
  CARD > NEWS
-------------------------------*/
.c-card_news{
  border-top:solid 1px $font_color;
  > *{
    border-bottom:solid 1px $font_color;
    padding: 1.5em .75em;
  }
  &__link{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: .4s;
    @include mq(sm-){
      &:hover{
        color: $main_color;
        transform: translateX(1em)
      }
    }
  }
  &__date{
    font-size: 1.32em;
    width:  8em;
    line-height: 1;
  }
  &__ttl{
    font-size: 1.1em;
    line-height: 1.2;
    width: calc(100% - 9.6em);
  }
  @include mq(xs){
    > *{
      padding: 1.5em 0;
    }
    &__link{
      display: block;
    }
    &__date{
      display: block;
      font-size: 1em;
      width: 100%;
    }
    &__ttl{
      display: block;
      font-size: 1em;
      width: 100%;
      margin-top: 1em;
    }
  }
}

/*-------------------------------
  CARD > PRODUCTS TERM
-------------------------------*/
.c-card_products_term{
  &__link{
    display: block;
    @include mq(sm-){
      &:hover{
        opacity: .5;
      }
    }
  }
  &__ttl{
    background: $main_color;
    font-size: 1.3em;
    font-weight: 500;
    color: #fff;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 1.85em;
  }
  @include mq(ss-){
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  @include mq(xs-){
    &__item{
      width: 23.5%;
      margin-right: 2%;
    }
  }
  @include mq(sm-){
    &__item{
      &:nth-child(4n){
        margin-right: 0;
      }
      &:nth-child(n+5){
        margin-top: $margin_lg;
      }
    }
  }
  @include mq(sm){
    &__item{
      width: 32%;
      &:nth-child(3n){
        margin-right: 0;
      }
      &:nth-child(n+4){
        margin-top: $margin_lg;
      }
    }
    &__ttl{
      font-size: 1.125em;
      margin-bottom: 1em;
    }
  }
  @include mq(xs){
    &__item{
      width: 48%;
      margin-right: 4%;
      &:nth-child(even){
        margin-right: 0;
      }
      &:nth-child(n+3){
        margin-top: $margin_md;
      }
    }
  }
  @include mq(ss){
    &__item{
      width: 100%;
      margin-right: 0;
      &:nth-child(n+2){
        margin-top: $margin_sm;
      }
    }
    &__link{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row-reverse;
    }
    &__ttl{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 65%;
      margin-bottom: 0;
      padding: 1em;
      font-size: 1em;
    }
    &__img{
      width: 35%;
    }
  }
  @include mq(screen){
    &__item{
      transition: .5s;
      @include mq(sm-){
        &:nth-child(4n+2){
          transition-delay: .25s;
        }
        &:nth-child(4n+3){
          transition-delay: .5s;
        }
        &:nth-child(4n){
          transition-delay: .75s;
        }

      }
      @include mq(sm){
        @include mq(xs-){
          &:nth-child(3n+2){
            transition-delay: .25s;
          }
          &:nth-child(3n){
            transition-delay: .5s;
          }
        }
      }
      @include mq(xs){
        @include mq(ss-){
          &:nth-child(even){
            transition-delay: .3s;
          }
        }
      }
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}
