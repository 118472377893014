@charset "utf-8";
/*============================================

  LIST

============================================*/
.c-tab{
  &__head{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &__item{
  }
  &__trg{
    &.is-act{
      pointer-events: none;
    }
    @include mq(sm-){
      &:hover{
      }
    }
  }
  &__body{
  }
  &__conts{
    display: none;
  }
  @include mq(sm){
    &__body{
    }
  }
}