@charset "utf-8";
/*============================================

  BUTTON WRAP

============================================*/
.c-btnwrap{
  @include mq(ss){
    text-align: center;
  }
}
/*============================================

  BUTTON PLAIN

============================================*/
.c-btn_pl{
  display: inline-block;
  background: $main_color;
  color: #fff;
  border:solid 1px $main_color;
  line-height: 1;
  padding: .25em 1.5em;
  font-size: 1.5em;
  @include mq(sm-){
    &:hover{
      background: #fff;
      color: $main_color;
    }
  }
  @include mq(ss){
    font-size: 1.25em;
  }
}
/*============================================

  BUTTON ARROW

============================================*/
.c-btn_ar{
}
