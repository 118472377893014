@charset "utf-8";
/*============================================

  アイコン

  ■一括置換用
  pattern：\.icon-(.*):before
  replace：@else if $type == '$1'

============================================*/
/*-------------------------------
  svg
-------------------------------*/
.i-line{
  fill: #3DC14C;
}
/*-------------------------------
  :before, :after
-------------------------------*/
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if $type == 'crowd04' {
    content: "\e92d";
  }
  @else if $type == 'crowd03' {
    content: "\e92c";
  }
  @else if $type == 'crowd02' {
    content: "\e900";
  }
  @else if $type == 'crowd01' {
    content: "\e901";
  }
  @else if $type == 'home' {
    content: "\e902";
  }
  @else if $type == 'sphere' {
    content: "\e903";
  }
  @else if $type == 'earth' {
    content: "\e904";
  }
  @else if $type == 'info' {
    content: "\e905";
  }
  @else if $type == 'info1' {
    content: "\e906";
  }
  @else if $type == 'cog' {
    content: "\e907";
  }
  @else if $type == 'map' {
    content: "\e908";
  }
  @else if $type == 'step' {
    content: "\e909";
  }
  @else if $type == 'track' {
    content: "\e90a";
  }
  @else if $type == 'train' {
    content: "\e90b";
  }
  @else if $type == 'clock' {
    content: "\e90c";
  }
  @else if $type == 'calendar' {
    content: "\e90d";
  }
  @else if $type == 'cart' {
    content: "\e90e";
  }
  @else if $type == 'user' {
    content: "\e90f";
  }
  @else if $type == 'enter' {
    content: "\e910";
  }
  @else if $type == 'exit' {
    content: "\e911";
  }
  @else if $type == 'dl' {
    content: "\e912";
  }
  @else if $type == 'note' {
    content: "\e913";
  }
  @else if $type == 'document' {
    content: "\e914";
  }
  @else if $type == 'file-excel' {
    content: "\e915";
  }
  @else if $type == 'file-word' {
    content: "\e916";
  }
  @else if $type == 'pdf' {
    content: "\e917";
  }
  @else if $type == 'zip' {
    content: "\e918";
  }
  @else if $type == 'window' {
    content: "\e919";
  }
  @else if $type == 'search' {
    content: "\f002";
  }
  @else if $type == 'free' {
    content: "\e91a";
  }
  @else if $type == 'tel' {
    content: "\e91b";
  }
  @else if $type == 'mobile' {
    content: "\e91c";
  }
  @else if $type == 'mail' {
    content: "\e91d";
  }
  @else if $type == 'mail1' {
    content: "\e91e";
  }
  @else if $type == 'bars' {
    content: "\e91f";
  }
  @else if $type == 'close' {
    content: "\ea0d";
  }
  @else if $type == 'checkmark' {
    content: "\e920";
  }
  @else if $type == 'arrow' {
    content: "\e921";
  }
  @else if $type == 'arrow02' {
    content: "\e922";
  }
  @else if $type == 'chevron-left' {
    content: "\f053";
  }
  @else if $type == 'chevron-right' {
    content: "\f054";
  }
  @else if $type == 'chevron-up' {
    content: "\f077";
  }
  @else if $type == 'chevron-down' {
    content: "\f078";
  }
  @else if $type == 'twitter' {
    content: "\f099";
  }
  @else if $type == 'facebook' {
    content: "\f09a";
  }
  @else if $type == 'facebook2' {
    content: "\e923";
  }
  @else if $type == 'instagram' {
    content: "\f16d";
  }
  @else if $type == 'line' {
    content: "\e924";
    color: #3DC14C;
  }
  @else if $type == 'line1' {
    content: "\e925";
    color: #3DC14C;
  }
  @else if $type == 'youtube' {
    content: "\e926";
  }
  @else if $type == 'youtube2' {
    content: "\e927";
  }
  @else if $type == 'darrow-left' {
    content: "\f100";
  }
  @else if $type == 'darrow-right' {
    content: "\f101";
  }
  @else if $type == 'darrow-top' {
    content: "\f102";
  }
  @else if $type == 'darrow-bottom' {
    content: "\f103";
  }
  @else if $type == 'arrow-left' {
    content: "\f104";
  }
  @else if $type == 'arrow-right' {
    content: "\f105";
  }
  @else if $type == 'arrow-top' {
    content: "\f106";
  }
  @else if $type == 'arrow-bottom' {
    content: "\f107";
  }
  @else if $type == 'circle-up' {
    content: "\ea41";
  }
  @else if $type == 'circle-right' {
    content: "\ea42";
  }
  @else if $type == 'circle-left' {
    content: "\ea44";
  }
  @else if $type == 'circle-down' {
    content: "\ea43";
  }
  @else if $type == 'caret-left' {
    content: "\e928";
  }
  @else if $type == 'caret-down' {
    content: "\e929";
  }
  @else if $type == 'caret-right' {
    content: "\e92a";
  }
  @else if $type == 'caret-up' {
    content: "\e92b";
  }


}
