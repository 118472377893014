@charset "utf-8";
/*============================================

  GLOBAL NAVIGATION

============================================*/
.p-gnavi{
  $amp: &;
  &__list{
    > li{
      line-height: 1;
      padding: 0 1.25em;
      font-weight: 700;
      a{
        display: block;
        font-size: .85em;
        text-align: center;
        > *{
          display: block;
        }
        em{
          font-style: normal;
        }
        span{
          font-size: .65em;
          margin-top: 1em;
        }
        @include mq(sm-){
          &:hover{
            color: $main_color;
          }
        }
      }
    }
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include mq(md){
    &__list{
      > li{
        a{
          font-size: 1em;
        }
      }
    }
  }
  @include mq(sm){
    // &__pulldown{
    //   background:none;
    //   >li{
    //   }
    // }
    background: $main_color;
    padding: $margin_md*3 $margin_sm $margin_sm;
    &__list{
      > li{
        border-bottom:dashed 1px #fff;
        padding: 0;
        a{
          font-size: 1.25em;
          color: #fff;
          padding: 1.25em 0;
        }
      }
    }
    @include mq(xs-){
      width: 40vw;
      &__list{
        > li{
          a{
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            span{
              margin-top: 0;
              margin-left: 2em;
            }
          }
        }
      }
    }
    @include mq(xs){
      @include mq(ss-){
        &__list{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          li{
            width: 48%;
          }
        }
      }
    }
  }
}
