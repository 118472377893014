@charset "utf-8";
/*============================================

  FORM

============================================*/
.c-form{
  $amp: &;
  &__sheet{
    &:not(:last-child){
      margin-bottom: $margin_lg*3;
      @include mq(sm){
        margin-bottom: $margin_ll;
      }
    }
    >tbody{
      >tr{
        >th,
        >td{
          text-align: left;
          line-height: 1.5;
          padding: .5em 1em;
          vertical-align: top;
        }
        >th{
          width: 30%;
        }
        >td{
          width: 70%;
        }
      }
    }
    @include mq(sm){
      >tbody{
        >tr{
          >th{
            width: 40%;
          }
          >td{
            width: 60%;
          }
        }
      }
    }
    @include mq(xs){
      >tbody{
        >tr{
          display: block;
          padding-bottom: 1.125em;
          >th,
          >td{
            display: block;
            padding: 0;
          }
          >th{
            width: 100%;
            margin-bottom: .75em
          }
          >td{
            width: 100%;
          }
          &:nth-last-child(n+2){
            padding-bottom: 1.125em;
            border-bottom:solid 1px #b2b2b2;
            margin-bottom: 1.125em;
          }
        }
      }
    }
  }
  &__ttl{
    display: inline-block;
    padding-right: .75em;
    padding-bottom: 1em;
    font-weight: inherit;
    font-weight: 500;
    &.-required{
      position: relative;
      &:after{
        position: absolute;
        content:"*";
        right: 0;
        top: 0;
        font-size: 1.125em;
        color:#c11;
      }
    }
    > *{
      display: block;
    }
    span{
      font-size: 1.5em;
    }
    em{
      font-style: normal;
    }
    @include mq(sm){
      span{
        font-size: 1.25em;
      }
    }
    @include mq(xs){
      padding-right: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-size: 1.125em;
      }
    }
  }
  &__row{

  }
  &__rowgroup{
    th,
    td{
      padding-bottom: .75em;
    }
    th{
      white-space: nowrap;
      font-weight: 400;
      padding-right: 2em;
    }
  }
  &__field{
    &:nth-child(n+2){
      margin-top: 1em;
    }
    .error{
      display: block;
      color:#c11;
      font-size: .875em;
      margin-top: .5em;
    }
  }
  &__error{
    color: #c11;
    font-size: .875em;
    margin-top: .5em;
  }
  &__notes{
    position: relative;
    margin-top: .5em;
    font-size: .875em;
    color:$font_color;
    opacity: .75;
    padding-left: 1em;
    &:before{
      position: absolute;
      content:"※";
      left: 0;
      top: 0;
    }
  }

  &__input{
    &.-text,
    &.-textarea{
      display: inline-block;
      vertical-align: middle;
      padding: 1em;
      background: #fff;
      border:solid 1px #818181;
      &.-short{
        width: 40%;
      }
      &.-middle{
        width: 60%;
      }
      &.-long{
        width: 80%;
      }
      &.-half{
        width: calc((100% - 2em) / 2);
        &:nth-child(n+2){
          margin-left: 1em;
        }
      }
      &.-full{
        width: 100%;
      }
    }
    &.-textarea{
      min-height:10.5em;
      line-height: 1.5;
    }
  }
  &__select{
    background: #f5f5f5;
    display: inline-block;
    max-width: 100%;
    padding: 1em 1.25em;
    line-height: 1;
  }

  &__check,
  &__radio{
    + *{
      position: relative;
      display: inline-block;
      padding: .25em 0 .25em 2.25em;
      line-height: 1;
    }
  }
  &__radio{
    + *{
      &:before,
      &:after{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        background: #f5f5f5;
        box-shadow: 0 0 1px rgba(0,0,0,.15);
      }
      &:after{
        background: $main_color;
        transform: scale(.4);
        visibility: hidden;
      }
    }
    &:checked{
      + *{
        &:after{
          visibility: visible;
        }
      }
    }
  }
  &__check{
    + *{
      &:before{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        border-radius: 3px;
        width: 1.5em;
        height: 1.5em;
        background: #f5f5f5;
      }
      &:after{
        position: absolute;
        content:"";
        left: 0;
        width: 1em;
        height: .65em;
        transform:rotate(-45deg) translate(0,50%);
        top: 0;
        border-left:solid 3px $main_color;
        border-bottom:solid 3px $main_color;
        visibility: hidden;
      }
    }
    &:checked{
      + *{
        &:after{
          visibility: visible;
        }
      }
    }
  }
  &__agreement{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;
    label{
      display: block;
    }
    input[type="checkbox"]{
      position: static;
      visibility: visible;
      appearance: auto;
      margin:0;
      border:solid 1px #818181;
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      border-radius: 0;
    }
    a{
      text-decoration: underline;
      padding-left:1em;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
    .error{
      color:#c11;
    }
    #{$amp}__error{
      width: 100%;
      text-align: center;
    }
    @include mq(xs){
      font-size: .875em;
    }
  }
  &__btnwrap{
    margin-top: $margin_lg*2;
    @include mq(xs-){
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include mq(sm){
      margin-top: $margin_lg;
    }
  }
  &__btn{
    cursor: pointer;
    display: block;
    color: #fff;
    line-height: 1;
    padding: .5em 1em;
    text-align: center;
    border: solid 1px $font_color;
    background: $font_color;
    transition:$transition_val;
    font-weight: 500;
    &.-back{
      background: #aaa;
      border-color: #aaa;
    }
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $font_color;
      }
      &.-back{
        &:hover{
          background: #fff;
          color: #aaa;
        }
      }
    }
    @include mq(xs-){
      &:nth-child(n+2){
        margin-left: 2em;
      }
    }
    @include mq(xs){
      width: 50%;
      margin:0 auto;
      &:nth-child(n+2){
        margin-top: 1.5em;
      }
    }
    @include mq(ss){
      width: 100%;
    }
  }
  &.-confirm{
    .cf_hidden{
      display: none !important;
    }
    #{$amp}__notes{
      display: none;
    }
    #{$amp}__error{
      display: none;
    }
    #{$amp}__agreement{
      display: none;
    }
  }
  &.-thanks{
    p{
      margin-bottom:1.5em;
      line-height: 2;
    }
  }
  &__loader{
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,.3);
    z-index: 999;
    aside{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      padding: $margin_lg;
      width: 94%;
      max-width: $base_width;
      font-size: 2em;
      background: #fff;
      text-align: center;
    }
  }
}
