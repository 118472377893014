@charset "utf-8";
/*============================================

  CONTACT

============================================*/
/*-------------------------------
  CONTACT > COMMON
-------------------------------*/
.p-privacy{
  padding-bottom: $margin_lg;
  &__block{
    counter-reset: sec_count 0;
    &:nth-child(n+2){
      margin-top: $margin_ll;
    }
  }
  &__cont{
    counter-increment: sec_count 1;
    &:nth-last-child(n+2){
      margin-bottom: $margin_lg;
    }
  }
  &__head{
    margin-bottom: $margin_md;
    p{
      margin-top: 1.5em;
    }
  }
  &__ttl{
    position: relative;
    font-size: 1.375em;
    padding-top: .75em;
    padding-left: 3em;
    padding-bottom: .75em;
    border-bottom: solid 1px $main_color;
    margin-bottom: 1em;
    font-weight: 500;
    &:before{
      position: absolute;
      left: 0;
      top: 0;
      font-size: 1.75em;
      font-weight: 900;
      font-style: italic;
      color: $main_color;
      content: counter(sec_count, decimal-leading-zero);
    }
  }
  &__notes{
    > p{
      position: relative;
      padding-left: 1.5em;
      &:before{
        position: absolute;
        content: "※";
        left: 0;
        top: 0;
      }
    }
    > ul{
      margin-top: .5em;
    }
  }
  &__txt{
    line-height: 1.6;
    font-weight: 400;
    strong{
      font-weight: 500;
    }
    ul{
      padding-left: 1.5em;
      > li{
        list-style: disc;
      }
    }
    dl{
      dt{
        position: relative;
        font-size: 1.125em;
        padding-left: 1.5em;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: .25em;
        &:before{
          position: absolute;
          content: "■";
          left: 0;
          top: 0;
          color: $main_color;
        }
      }
      dd{
        &:nth-last-child(n+2){
          margin-bottom: 1em;
        }
        ul{
          &:nth-last-child(n+2){
            margin-bottom: 1em;
          }
        }
      }
    }
    aside{
      position: relative;
      border:solid 1px $main_color;
      margin-top: 1.5em;
      padding: 1.5em;
      h4{
        position: absolute;
        padding: 0 1em;
        color: $main_color;
        left: 0;
        top: 0;
        background: #fff;
        transform: translateY(-50%);
      }
      p{
        font-size: .875em;
      }
      &:nth-child(n+2){
        margin-top: 2em;
      }
    }
    > *{
      &:nth-child(n+2){
        margin-top: 1em;
      }
    }
  }
  @include mq(sm){
    &__ttl{
      font-size: 1.25em;
    }
  }
  @include mq(xs){
    &__ttl{
      line-height: 1.4;
    }
  }
  @include mq(ss){
    &__head{
      margin-bottom: $margin_sm;
    }
    &__cont{
      &:nth-last-child(n+2){
        margin-bottom: $margin_md;
      }
    }
    &__ttl{
      font-size: 1.125em;
      padding-left: 0;
      &:before{
        display: none;
      }
    }
  }
}
