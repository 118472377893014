@charset "utf-8";
/*============================================

  RECRUIT

============================================*/
/*-------------------------------
  RECRUIT > LEADING
-------------------------------*/
.p-recruit_leading{
  $amp : &;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: $margin_md*4;
  &__ttl{
    order: 1;
    width: 100%;
    font-weight: 700;
    font-size: 1.4em;
    margin-bottom: 2em;
  }
  &__txt,
  &__img{
    width: 50%;
  }
  &__txt{
    order: 2;
    padding-right: $margin_md*2;
  }
  &__img{
    order: 3;
    text-align: center;
    img{
      max-width: 100%;
      text-align: center;
    }
  }
  @include mq(lg){
    padding-bottom: $margin_md*3;
  }
  @include mq(md){
    &__img{
      width: 40%;
    }
    &__txt{
      width: 55%;
      padding-right: 0;
    }
  }
  @include mq(sm){
    padding-bottom: $margin_md*2;
    &__ttl{
      font-size: 1.3em;
      margin-bottom: 1.5em;
    }
  }
  @include mq(xs){
    display: block;
    &__img,
    &__txt{
      width: 100%;
    }
    &__img{
      margin:0 auto $margin_md;
    }
    @include mq(ss-){
      &__img{
        width: 75%;
      }
    }
  }
  @include mq(ss){
    padding-bottom: $margin_md;
    &__ttl{
      font-size: 1.2em;
      margin-bottom: 1em;
    }
    &__img{
      margin-bottom: $margin_sm;
    }
  }
  @include mq(screen){
    @include mq(xs-){
      &__ttl,
      &__txt,
      &__img{
        transition:.5s;
        @include animation('fadeLeft');
      }
      &__txt{
        transition-delay: .5s;
      }
      &__img{
        transition-delay: 1s;
      }
      &.is-anime{
        #{$amp}__ttl,
        #{$amp}__txt,
        #{$amp}__img{
          @include animation('fadeLeft', 'after');
        }
      }
    }
    @include mq(xs){
      transition:.5s;
      @include animation('fadeUp');
      transition-delay:1s;
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}
/*-------------------------------
  RECRUIT > INTERVIEW
-------------------------------*/
.p-recruit_interview{
  $amp : &;
  border-bottom:solid .5rem $main_color;
  &__head{
    position: relative;
    margin-bottom: $margin_ss*4;
  }
  &__title{
    position: relative;
  }
  &__thumb{
    position: relative;
    width: 72%;
    margin-left: auto;
    z-index: 1;
  }
  &__catch{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 2.5em;
    color: $main_color;
    z-index: 2;
    > *{
      display: block;
    }
    em{
      font-style: normal;
      font-weight: 900;
      font-size: 3.2em;
      line-height: 1;
    }
    span{
      margin-top: 1em;
      line-height: 1.2;
      font-weight: 700;
    }
  }
  &__info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__name{
    width: 28%;
    font-size: 1.25em;
    > *{
      display: block;
    }
    span{
      line-height: 1.4;
      margin-bottom: .5em;
    }
    em{
      font-style: normal;
      font-size: 2.4em;
    }
  }
  &__desc{
    width: 72%;
    padding-top: 2.5em;
    h4{
      font-size: 1.1em;
      line-height: 1.65;
    }
    p{
      font-size: .9em;
      line-height: 2;
    }
  }
  &__block{
    padding: 4rem $margin_md;
    &:nth-child(odd){
      background: #d9d9d9;
    }
  }
  &__box{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &.-reverse{
      flex-direction: row-reverse;
    }
  }
  &__img{
    width: calc(50% - #{$margin_sm*2});
    img{
      width: 100%;
    }
  }
  &__txt{
    width: 50%;
  }
  &__lead{
    font-size: 1.75em;
    line-height: 1.43;
    margin-bottom: .75em;
    &:not(:first-child){
      margin-top:  1.25em;
    }
  }
  &__subttl{
    margin-bottom: 1em;
  }
  &__gallery{
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
    > figure{
      width: 25%;
      img{
        width: 100%;
      }
    }
  }
  &__caption{
    margin-top: 1em;
  }
  &__img{
    &.-stroke01,
    &.-stroke02,
    &.-stroke03,
    &.-stroke04,
    &.-stroke05{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    object{
      width: 100%;
    }
    &.-stroke01{
      object{
        height: 11rem;
      }
    }
    &.-stroke02{
      object{
        height: 18rem;
      }
    }
    &.-stroke03{
      object{
        height: 17rem;
      }
    }
    &.-stroke04{
      object{
        height: 22.5rem;
      }
    }
    &.-stroke05{
      object{
        height: 21rem;
      }
    }
  }
  @include mq(lg){
    &__catch{
      em{
        font-size: 2.5em;
      }
    }
    &__name{
      em{
        font-size: 2.1em;
      }
    }
    &__desc{
      padding-top: 2em;
    }
    &__block{
      padding: 4rem 0;
    }
  }
  @include mq(md){
    &__catch{
      font-size: 2.2em;
    }
    &__name{
      font-size: 1.125em;
    }
    &__lead{
      font-size: 1.625em;
    }
  }
  @include mq(sm){
    &__catch{
      font-size: 1.875em;
    }
    &__desc{
      padding-top: 1.5em;
    }
    &__lead{
      font-size: 1.5em;
    }
  }
  @include mq(xs){
    border-bottom-width: .375rem;
    &__head{
      margin-bottom: $margin_lg;
    }
    &__catch{
      font-size: 1.625em;
      em{
        font-size: 2.25em;
      }
    }
    &__thumb{
      width: 65%;
    }
    &__name{
      padding-top: $margin_sm;
      width: 35%;
      em{
        font-size: 1.75em;
      }
    }
    &__desc{
      width: 65%;
    }
    &__box{
      display: block;
    }
    &__img{
      width: 100%;
      margin-bottom: $margin_sm;
    }
    &__txt{
      width: 100%;
    }
  }
  @include mq(ss){
    &__title{
      position: static;
    }
    &__thumb{
      position: relative;
      font-size: 2.5em;
      width: 100%;
      padding-top: .5em;
      margin-bottom: .5em;
      img{
        position: relative;
        z-index: 1;
      }
      &:before{
        position: absolute;
        top: 0;
        left: 0;
        content: attr(data-number);
        font-weight: 900;
        color: $main_color;
        line-height: 1;
        z-index: 2;
      }
    }
    &__catch{
      position: static;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      font-size: 1.375em;
      padding-bottom: 1em;
      span{
        padding-left: 0;
        margin-top: 0;
        br{
          display: none;
        }
      }
    }
    &__info{
      display: block;
    }
    &__name{
      padding-top: 0;
      width: 100%;
      font-size: 1em;
      em{
        font-size: 1.5em;
      }
    }
    &__desc{
      width: 100%;
    }
    &__lead{
      font-size: 1.375em;
    }
    &__gallery{
      flex-wrap: wrap;
      figure{
        width: 50%;
      }
    }
  }
  @include mq(screen){
    @include mq(ss-){
      &__head{
        #{$amp}__catch,
        #{$amp}__thumb,
        #{$amp}__name,
        #{$amp}__desc{
          transition:.5s;
          @include animation('fadeLeft');
        }
        #{$amp}__thumb{
          transition-delay:.25s;
        }
        #{$amp}__name{
          transition-delay:.75s;

        }
        #{$amp}__desc{
          transition-delay:1s;
        }
        &.is-anime{
          #{$amp}__catch,
          #{$amp}__thumb,
          #{$amp}__name,
          #{$amp}__desc{
            @include animation('fadeLeft', 'after');
          }
        }
      }
    }
    @include mq(ss){
      &__head{
        transition:.5s;
        @include animation('fadeUp');
        &.is-anime{
          @include animation('fadeUp', 'after');
        }
      }
    }
    @include mq(xs-){
      &__block{
        #{$amp}__txt,
        #{$amp}__img,
        #{$amp}__subttl,
        #{$amp}__gallery,
        #{$amp}__caption{
          transition:.4s;
        }
        #{$amp}__txt{
          @include animation('fadeLeft');
          transition-delay:.4s;
        }
        #{$amp}__img{
          transition-delay:0s;
          &:not(.-stroke01),
          &:not(.-stroke02),
          &:not(.-stroke03),
          &:not(.-stroke04),
          &:not(.-stroke05){
            @include animation('fadeLeft');
          }
        }
        #{$amp}__subttl,
        #{$amp}__gallery,
        #{$amp}__caption{
          @include animation('fadeUp');
        }
        #{$amp}__gallery{
          transition-delay:.2s;
        }
        #{$amp}__caption{
          transition-delay:.4s;
        }
        #{$amp}__box{
          &.-reverse{
            #{$amp}__txt{
              transition-delay:0s;
            }
            #{$amp}__img{
              transition-delay:.4s;
            }
          }
        }
        &.is-anime {
          #{$amp}__txt{
            @include animation('fadeLeft', 'after');
          }
          #{$amp}__img{
            &:not(.-stroke01),
            &:not(.-stroke02),
            &:not(.-stroke03),
            &:not(.-stroke04),
            &:not(.-stroke05){
              @include animation('fadeLeft', 'after');
            }
          }
          #{$amp}__subttl,
          #{$amp}__gallery,
          #{$amp}__caption{
            @include animation('fadeUp', 'after');
          }
        }
      }
    }
    @include mq(xs){
      &__block{
        @include animation('fadeUp');
        transition:.5s;
        &.is-anime {
          @include animation('fadeUp', 'after');
        }
      }
    }
  }

}

/*-------------------------------
  RECRUIT > RELATION
-------------------------------*/
.p-recruit_relation{
  padding-top: $margin_sm*4;
  margin-bottom: $margin_md*4;
  @include mq(sm){
    padding-top: $margin_lg;
    margin-bottom: $margin_lg*2;
  }
  @include mq(ss){
    padding-top: $margin_lg;
    margin-bottom: $margin_lg;
  }
  @include mq(screen){
    transition:.4s;
    @include animation('fadeUp');
    &.is-anime{
      @include animation('fadeUp', 'after');
    }
  }
}

/*-------------------------------
  RECRUIT > GUIDELINE
-------------------------------*/
.p-recruit_guideline{
  &__head{
    margin-bottom: $margin_ss*4;
  }
  &__body{
    padding-bottom: $margin_ss*8;
  }
  @include mq(sm){
    &__head{
      margin-bottom: $margin_sm*2;
    }
    &__body{
      padding-bottom: $margin_sm*4;
    }
  }
  @include mq(ss){
    &__head{
      margin-bottom: $margin_ss*2;
    }
  }
  @include mq(screen){
    transition:.4s;
    @include animation('fadeUp');
    &.is-anime{
      @include animation('fadeUp', 'after');
    }
  }
}
