@charset "utf-8";
/*============================================

  OTHER COMPONENT

============================================*/

/*-------------------------------
  TOTOP
-------------------------------*/
.c-totop{
  position: fixed;
  right: 2vw;
  bottom : 2vw;
  z-index: 9999;
  transition-property: opacity;
  display: none;
  span{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border:solid 1px #fff;
    width: 6em;
    height: 6em;
    font-size: .75em;
    line-height: 1.5;
    text-align: center;
    font-weight: 400;
    background: $main_color;
  }
  @include mq(sm-){
    &:hover{
      opacity: .5;
    }
  }
}
