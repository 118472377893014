@charset "utf-8";
/*============================================

  SECTION

============================================*/
.p-section{
  $amp: &;
  overflow: hidden;
  padding-top: 2.75em;
  &__title{
    position: relative;
    font-size: 1.6em;
    margin-bottom: 3em;
    line-height: 1;
    font-weight: 700;
    z-index: 4;
    > * {
      position: relative;
      display: inline-block;
      z-index: 2;
    }
    em{
      font-size: 5em;
      color: $main_color;
    }
    span{
      margin-left: 1em;
    }
    &:before,
    &:after{
      position: absolute;
      content: "";
      top: 55%;
      width: 100vw;
      height: 3.75em;
      background: $main_color;
      transform:skewX(-12deg) translateY(-50%);
      transform-origin: left top;
      z-index: 1;
    }
    &:before{
      right: calc(100% + 4rem);
    }
    &:after{
      left: calc(100% + 4rem);
    }
  }
  &__content{
    position: relative;
    padding-bottom: 3.5rem;
    z-index: 3;
  }
  &__img{
    position: relative;
    z-index: 3;
    width: 100%;
    height: 12.5rem;
  }
  &.-line01,
  &.-line02{
    position: relative;
    &:before,
    &:after{
      position: absolute;
      content: "";
      width: 100%;
      background: $main_color;
      opacity: .1;
    }
    &:before{
      left: 0;
      z-index: 1;
      height: 6em;
      transform: skewY(6deg);
    }
    &:after{
      right: 0;
      z-index: 2;
      height: 25em;
      transform: skewY(-8deg);
    }
  }
  &.-line01{
    &:before,
    &:after{
      top: 6.5em;
      transform-origin: right top;
    }
  }
  &.-line02{
    padding-bottom: $margin_lg;
    &:before{
      top: 10rem;
      transform-origin: left top;
    }
    &:after{
      transform-origin: left bottom,;
      bottom: 0;
    }
    + #{$amp}{
      margin-top: -$margin_lg;

    }
  }
  @include mq(sm-){
    &__title{
      &.-hasImage{
        &.-img_lg{
          padding-right: 25rem;
          img{
            width: 25rem;
          }
        }
        &.-img_md{
          padding-right: 19.25rem;
          img{
            width: 19.25rem;
          }
        }
        &.-img_sm{
          padding-right: 17rem;
          img{
            width: 17rem;
          }
        }
      }
      @include mq(lg){
        &.-hasImage{
          &.-img_lg{
            padding-right: 20rem;
            img{
              width: 20rem;
            }
          }
          &.-img_md{
            padding-right: 15.4rem;
            img{
              width: 15.4rem;
            }
          }
          &.-img_sm{
            padding-right: 13.6rem;
            img{
              width: 13.6rem;
            }
          }
        }
      }
    }
  }
  @include mq(lg){
    &__title{
      &:before,
      &:after{
        width: 7.5rem;
      }
      &:before{
        right: calc(100% + 1.5rem);
      }
      &:after{
        left: calc(100% + 1.5rem);
      }
    }
  }
  @include mq(md){
    &__title{
      em{
        font-size: 4em;
      }
      &:before,
      &:after{
        height: 3.25em
      }
    }
  }
  @include mq(sm){
    &__title{
      em{
        font-size: 3.75em;
      }
      &:before,
      &:after,
      img{
        display: none;
      }
    }
  }
  @include mq(xs){
    &__title{
      margin-bottom: 2em;
      em{
        font-size: 3em;
      }
    }
  }
  @include mq(ss){
    &:before,
    &:after{
      display: none;
    }
    &__title{
      font-size: 1.375em;
      > *{
        display: block;
      }
      em{
        font-size: 2.5em;
        font-weight: 900;
      }
      span{
        margin-left: 0;
        margin-top: .75em;
      }
    }
  }
  @include mq(screen){
    &__title{
      transition:.5s;
      >*{
        transition: inherit;
        @include animation('fadeLeft');
      }
      &:before,
      &:after{
        transition: inherit;
        transform:skewX(-12deg) translateY(-50%) scaleX(0);
      }
      &:before{
        transition-delay:0s;
      }
      em{
        transition-delay:.5s;
      }
      span{
        transition-delay:1s;
      }
      &:after{
        transition-delay:1.5s;
      }
      &.-hasImage{
        img{
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          transition-delay: 1.5s;
        }
        &:after{
          transition-delay: 2s;
        }
      }
      @include mq(sm){
        em{
          transition-delay:0s;
        }
        span{
          transition-delay:.5s;
        }
      }
    }
    &.-line01,
    &.-line02{
      &:before{
        width: 0;
        transition:.5s linear;
      }
      &:after{
        width: 0;
        transition:.5s linear .5s;
      }
    }
    &.is-anime{
      &.-line01,
      &.-line02{
        &:before{
          width: 100%;
        }
        &:after{
          width: 100%;
        }
      }
      #{$amp}__title{
        > *{
          @include animation('fadeLeft', 'after');
        }
        &:before,
        &:after{
          transform:skewX(-12deg) translateY(-50%) scaleX(1);
        }
      }
    }
  }
}
