@charset "utf-8";

/*============================================

  IMAGE

============================================*/
/*-------------------------------
  CASE > OBJECT FIT
-------------------------------*/
.c-ofi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    font-family: "object-fit: cover;object-position:center center;";

    object-fit: cover;
    object-position: center center;

    &.-contain {
      font-family: "object-fit: contain;object-position:center center;";

      object-fit: contain;
    }

    &.left_img {
      @media screen and (max-width: 1200px) {
        object-position: left;
      }

      @media screen and (max-width: 768px) {
        object-position: -6em;
      }

      @media screen and (max-width: 575px) {
        object-position: -3em;
      }
    }
  }
}

/*-------------------------------
  CASE > GALLERY
-------------------------------*/
.c-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @include mq(xs) {
    @include mq(ss-) {
      >a {
        width: 23.5%;
        margin-right: 2%;
        padding-top: 20%;

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-child(n+5) {
          margin-top: 2%;
        }
      }
    }
  }

  @include mq(ss) {
    >a {
      width: 48%;
      margin-right: 4%;
      padding-top: 32%;

      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(n+3) {
        margin-top: 4%;
      }
    }
  }

  >a {
    display: block;
    position: relative;
    background: #ffffff;

    @include mq(xs-) {
      width: 18%;
      margin-right: 2.5%;
      padding-top: 15%;

      &:nth-child(5n) {
        margin-right: 0;
      }

      &:nth-child(n+6) {
        margin-top: 2.5%;
      }
    }

    @include mq(sm-) {
      &:hover {
        img {
          opacity: .5;
        }
      }
    }

    img {
      transition: $transition_val;
    }
  }
}