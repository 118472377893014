@charset "utf-8";
/*============================================

  TABLE PRIMARY

============================================*/
.c-tbl_pr{
  $amp: &;
  border-top:solid 1px #e5e5e5;
  tr{
    border-bottom:solid 1px #e5e5e5;
  }
  th,
  td{
    text-align: left;
    padding: 1.5em 0;
    vertical-align: top;
    line-height: 1.875;
  }
  th{
    width: 25%;
    font-weight: 400;
  }
  &.-wht{
    border-color:#fff;
    color:#fff;
    tr{
      border-color:#fff;
    }
  }
  @include mq(xs){
    tr{
      display: block;
      padding: 1.5em 0;
    }
    th,
    td{
      display: block;
      padding: 0;
    }
    th{
      font-size: 1.125em;
      width: 100%;
      margin-bottom: .5em;
    }
  }
}
