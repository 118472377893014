@charset "utf-8";

/*============================================

  PRODUCTS

============================================*/
/*-------------------------------
  PRODUCTS > POLICY
-------------------------------*/
.p-products_policy {
  @include mq(screen) {
    transition: .5s;

    @include animation("fadeIn");

    @include mq(sm-) {
      transition-delay: 2.5s;
    }

    @include mq(sm) {
      transition-delay: 1s;
    }

    &.is-anime {
      @include animation("fadeIn", "after");
    }
  }
}

/*-------------------------------
  PRODUCTS > DETAIL
-------------------------------*/
.p-products_detail {
  margin-top: $margin_ll;
  $amp: &;

  @include mq(md) {
    &__desc {
      font-size: 1.5em;
    }
  }

  @include mq(sm) {
    @include mq(xs-) {
      &__others {
        >* {
          width: 48%;

          &:nth-child(n+3) {
            margin-top: $margin_sm*2;
          }
        }
      }
    }

    &__txt {
      width: 55%;
    }

    &__img {
      width: 40%;
    }

    &__desc {
      font-size: 1.375em;
    }

    &__item_sub {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__item_sub {
      >figure {
        width: 35%;
        margin-bottom: 0;
      }

      >aside {
        width: 60%;
      }
    }
  }

  @include mq(xs) {
    &__item {
      display: block;
    }

    &__txt {
      width: 100%;
    }

    &__img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 2em;

      >figure {
        width: 48%;

        &:nth-child(n+2) {
          margin-top: 0;
        }
      }
    }

    &__others {
      >* {
        width: 100%;

        &:nth-child(n+2) {
          margin-top: $margin_sm;
        }
      }
    }

    &__item_sub {
      >figure {
        width: 30%;
      }

      >aside {
        width: 65%;
      }
    }
  }

  @include mq(ss) {
    &__desc {
      font-size: 1.25em;
    }

    &__item_sub {
      aside {
        h2 {
          span {
            margin-top: .5em;
          }
        }
      }
    }
  }

  @include mq(screen) {
    @include mq(sm-) {
      &__others {
        >* {
          transition: .5s;

          @include animation("fadeUp");

          @for $i from 2 through 10 {
            &:nth-child(#{$i}) {
              transition-delay: #{$i*.25-.25}s;
            }
          }
        }

        &.is-anime {
          >* {
            @include animation("fadeUp", "after");
          }
        }
      }
    }

    @include mq(xs-) {

      &__txt,
      &__img {
        transition: .5s;

        @include animation("fadeLeft");
      }

      &__img {
        transition-delay: .5s;
      }

      &__item {
        &.is-anime {

          #{$amp}__txt,
          #{$amp}__img {
            @include animation("fadeLeft", "after");
          }
        }
      }
    }

    @include mq(sm) {
      @include mq(xs-) {
        &__others {
          >* {
            transition: .5s;

            @include animation("fadeLeft");

            @for $i from 2 through 10 {
              &:nth-child(#{$i}) {
                transition-delay: #{$i*.25-.25}s;
              }
            }
          }

          &.is-anime {
            >* {
              @include animation("fadeLeft", "after");
            }
          }
        }
      }
    }
  }

  @include mq(xs) {
    &__item {
      transition: .5s;

      @include animation("fadeUp");

      &.is-anime {
        @include animation("fadeUp", "after");
      }
    }

    &__others {
      >* {
        transition: .5s;

        @include animation("fadeUp");

        @for $i from 2 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: #{$i*.25-.25}s;
          }
        }
      }

      &.is-anime {
        >* {
          @include animation("fadeUp", "after");
        }
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      display: block;
    }

    >* {
      width: 48%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &:nth-child(n+2) {
      margin-top: $margin_lg;
    }
  }

  &__img {
    >figure {
      &:nth-child(n+2) {
        margin-top: 1em;

        @media screen and (max-width: 767px) {
          margin-top: 0;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  &__desc {
    margin-bottom: .6em;
    font-size: 1.72vw;
    line-height: 1.2;

    @media screen and (max-width: 991px) {
      font-size: 1.98vw;
    }

    @media screen and (max-width: 767px) {
      font-size: 4.1vw;
    }

    @media screen and (max-width: 500px) {
      font-size: 4.2vw;
    }
  }

  &__others {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4.5rem;

    @media screen and (max-width: 767px) {}

    >* {
      width: 23.5%;

      @media screen and (max-width: 991px) {
        width: 48%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__item_sub {
    figure {
      margin-bottom: 1em;
    }

    aside {
      h2 {
        line-height: 1.4;

        >* {
          display: block;
        }

        em {
          font-style: normal;
        }

        span {
          margin-top: 1em;
          font-size: .9em;
        }
      }

      p {
        margin-top: .5em;
        font-size: .8em;
      }
    }
  }
}

/*-------------------------------
  PRODUCTS > CATEGORY
-------------------------------*/
.p-products_category {
  margin-top: $margin_lg*2;
  padding-top: $margin_lg*2+$margin_sm;
  padding-bottom: 9.5rem;
  border-top: solid .5rem $main_color;

  @include mq(md) {
    margin-top: $margin_md*2;
    padding-top: $margin_md*2+$margin_ss;
    padding-bottom: $margin_md*3;
  }

  @include mq(xs) {
    margin-top: $margin_lg;
    padding-top: $margin_ll;
    border-top-width: .375rem;
  }

  @include mq(xs) {
    margin-top: $margin_md;
    padding-top: $margin_lg;
  }

  section {
    &:nth-child(n+2) {
      margin-top: $margin_lg*2;
    }
  }
}

/*-------------------------------
  PRODUCTS > ARCHIVE
-------------------------------*/
.p-products_archive {
  padding: $margin_ll 0 $margin_ll*2;
  border-top: solid .5rem $main_color;

  @include mq(sm) {
    padding: $margin_lg 0 $margin_md*3;

    &__list {
      margin-bottom: $margin_md;
    }
  }

  &__list {
    margin-bottom: $margin_lg;

    @media screen and (max-width: 991px) {
      margin-bottom: 2.5rem;
    }
  }

  &__btn {
    text-align: center;
  }
}

/*-------------------------------
  PRODUCTS > BODY
-------------------------------*/
.p-products_body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mq(sm) {
    &__name {
      font-size: 1.4em;
    }

    &__ttl {
      font-size: 1.125em;
    }

    &__desc {
      font-size: 1.125em;
    }

    &__list {
      aside {
        font-size: .875em;
      }
    }
  }

  @include mq(xs) {
    display: block;

    >* {
      width: 100%;
    }

    &__img {
      margin: 0 auto $margin_sm;
      padding-right: 0;
      border-top: solid .375rem $main_color;
    }
  }

  @include mq(ss) {
    &__name {
      font-size: 1.25em;
    }

    &__ttl {
      font-size: 1em;
    }

    &__desc {
      font-size: 1em;
    }

    &__list {
      display: block;
      margin-top: 2em;

      >li {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        &:nth-child(n+2) {
          margin-top: 1em;
        }
      }

      figure {
        width: 40%;
      }

      aside {
        width: 60%;
        margin-top: 0;
        padding-left: 1em;
        font-size: .75em;
      }
    }
  }

  &:nth-child(n+2) {
    margin-top: $margin_lg;
  }

  >* {
    width: 50%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__img {
    padding-right: $margin_ss*2;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0 auto 1.5rem;
      padding-right: 0;
      border-top: solid .375rem #07824b;
    }
  }

  &__name {
    margin-bottom: .5em;
    padding-bottom: .3em;
    border-bottom: solid 2px $font_color;
    font-size: 1.6em;
    line-height: 1.4;

    @media screen and (max-width: 991px) {
      font-size: 1.4em;
    }

    @media screen and (max-width: 575px) {
      font-size: 1.25em;
    }
  }

  &__ttl {
    margin-bottom: .5em;
    font-weight: 500;
    font-size: 1.25em;

    @media screen and (max-width: 991px) {
      font-size: 1.125em;
    }

    @media screen and (max-width: 575px) {
      font-size: 1em;
    }

    span {
      display: inline-block;
      padding: .25em .5em;
      background: #d0d0d0;
    }
  }

  &__desc {
    font-size: 1.2em;
    line-height: 1.6;

    @media screen and (max-width: 575px) {
      font-size: 1em;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 3em;

    >li {
      width: 32%;
      margin-right: 2%;

      @media screen and (max-width: 575px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }

      &:nth-child(3n),
      &:last-child {
        margin-right: 0;
      }

      &:nth-child(n+4) {
        margin-top: 1em;
      }
    }

    aside {
      margin-top: .75em;
      font-size: .7em;
      line-height: 1.6;

      >p {
        position: relative;
        padding-left: 1em;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "・";
        }
      }
    }
  }
}

/*-------------------------------
  PRODUCTS > OPTION
-------------------------------*/
.p-products_option {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  >li {
    width: 32%;
    margin-right: 2%;

    &:nth-child(3n),
    &:last-child {
      margin-right: 0;
    }

    &:nth-child(n+4) {
      margin-top: 3em;
    }
  }

  &__name {
    margin-top: .85em;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 1.3;
  }
}