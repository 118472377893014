@charset "utf-8";
/*============================================

  PAGER

============================================*/
.c-pager{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $margin_md;
  &__link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    background: #fff;
    color: $font_color;
    border:solid 1px $main_color;
    &:nth-child(n+2){
      margin-left: .5em;
    }
    &.-current{
      background: $font_color;
      color:#fff;
      pointer-events: none;
    }
    @include mq(sm-){
      &:hover{
        background: $font_color;
        color: #fff;
      }
    }
  }
}