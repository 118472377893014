@charset "utf-8";
/*============================================

  TITLE

============================================*/
/*-------------------------------
  TITLE > PRIMARY
-------------------------------*/
.c-ttl_pr{
  font-size: 2.8em;
  line-height: 1.2;
  font-weight: 700;
  color: $main_color;
  margin-bottom: 1.25em;
  @include mq(sm){
    font-size: 2.1em;
  }
  @include mq(ss){
    font-size: 1.75em;
  }
}


/*-------------------------------
  TITLE > PLAIN
-------------------------------*/
.c-ttl_pl{
  background: $main_color;
  color: #fff;
  line-height: 1.2;
  font-weight: 500;
  font-size: 1.6em;
  padding: .25em .5em;
  margin-bottom: 2.625em;
  @include mq(sm){
    font-size: 1.4em;
    margin-bottom: 2em;
  }
  @include mq(ss){
    font-size: 1.25em;
    margin-bottom: 1.75em;
  }
}
/*-------------------------------
  TITLE > LINE
-------------------------------*/
.c-ttl_uline{
  font-size: 2em;
  font-weight: 700;
  padding-bottom: .5em;
  border-bottom: solid 1px $main_color;
  margin-bottom: .5em;
  line-height: 1.2;
  @include mq(sm){
    font-size: 1.625em;
  }
  @include mq(ss){
    font-size: 1.375em;
  }
}

/*-------------------------------
  TITLE > SUPPLEMENT
-------------------------------*/
.c-ttl_sup{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  line-height: 1.2;
  font-size: 1.5em;
  margin-bottom: 3em;
  > *{
    display: block;
  }
  em{
    font-style: normal;
    font-size: 1.8em;
    font-weight: 900;
  }
  span{
    font-weight: 500;
    margin-left: 1.75em;
  }
  @include mq(sm){
    font-size: 1.375em;
    margin-bottom: 2em;
  }
  @include mq(xs){
    display: block;
    span{
      margin-left: 0;
      margin-top: .5em;
    }
  }
  @include mq(ss){
    font-size: 1.25em;
    em{
      font-size: 1.6em;
    }
  }
}
/*-------------------------------
  TITLE > SUPPLEMENT LINE
-------------------------------*/
.c-ttl_sup_uline{
  font-size: 2em;
  padding-bottom: .5em;
  border-bottom: solid 1px $main_color;
  margin-bottom: .5em;
  line-height: 1.2;
  > * {
    display: block;
  }
  em{
    font-style: normal;
    font-size: 1.25em;
    color : $main_color;
    margin-bottom: .5em;
    font-weight: 900;
  }
  @include mq(sm){
    font-size: 1.625em;
  }
  @include mq(ss){
    font-size: 1.375em;
  }
}
