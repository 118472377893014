@charset "utf-8";
/*============================================

  HEADER

============================================*/
.l-header{
  $amp: &;
  padding: 1.5em 0 1em;
  font-family: 'M PLUS 1p', sans-serif;
  border-bottom:solid .5em $main_color;
  &__inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    a{
      display: block;
    }
    svg{
      width: 10.75em;
      height: 2.65em;
    }
  }
  &__desc{
    font-size: .6em;
    line-height: 1.5;
    margin-left: 2em;
    font-weight: 700;
  }
  &__hamburger{
    width: 4rem;
    height: 4rem;
    background: $main_color;
    span{
      position: absolute;
      width: 60%;
      height: 2px;
      background: #fff;
      left: 20%;
      top: calc(50% - 1px);
      transition:$transition_val;
      &:before,
      &:after{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform-origin: center center;
        background: #fff;
        transition:inherit;
      }
      &:before{
        transform:translateY(-1rem);
      }
      &:after{
        transform:translateY(1rem);
      }
    }
    .slideout-open &{
      span{
        background: transparent;
        &:before{
          transform:rotate(45deg);
        }
        &:after{
          transform:rotate(-45deg);
        }
      }
    }
  }
  @include mq(xs){
    border-bottom-width: .375rem;
  }
  @include mq(ss){
    &__logo{
      svg{
        width: 8.0625em;
        height: 1.9875em;
      }
    }
    &__hamburger{
      width: 3rem;
      height: 3rem;
      span{
        &:before{
          transform:translateY(-.5rem);
        }
        &:after{
          transform:translateY(.5rem);
        }
      }
    }
  }
}
