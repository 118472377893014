@charset "utf-8";
/*============================================

  LIST

============================================*/
/*-------------------------------
  LIST > PRIMARY
-------------------------------*/
.c-list_pr{
}
/*-------------------------------
  LIST > NUMBER
-------------------------------*/
.c-list_num{
  counter-reset : list_num 0;
  > *{
    counter-increment: list_num 1;
    position: relative;
    padding-left: 2em;
    &:before{
      position: absolute;
      content: counter(list_num);
      left: 0;
      top: 0;
    }
  }
  @include mq(sm){
    > *{
      padding-left: 1.5em;
    }
  }
}
/*-------------------------------
  LIST > CIRCLE
-------------------------------*/
.c-list_circle{
  > *{
    position: relative;
    padding-left: 1.25em;
    &:before{
      position: absolute;
      content: "●";
      left: 0;
      top: 0;
    }
  }
}
