@charset "utf-8";
/*============================================

  PANEL

============================================*/
/*-------------------------------
  PANEL > LINE
-------------------------------*/
.p-panel_line{
  position: relative;
  font-size: 2em;
  &__ttl{
    position: absolute;
    top: 0;
    left: 1px;
    z-index: 2;
    padding: 0 1em;
    background: #fff;
    font-weight: 700;
    color: $main_color;
    line-height: 1;
    transform: translateY(-50%);
  }
  &__content{
    position: relative;
    z-index: 1;
    padding: 1.75em 4em 1.5em;
    border: solid 1px $main_color;
  }
  @include mq(lg){
    font-size: 1.75em;
    &__content{
      padding: 1.75em 3em 1.5em;
    }
  }
  @include mq(md){
    font-size: 1.5em;
  }
  @include mq(sm){
    &__content{
      padding: 1.5em 2em 1.25em;
    }
  }
  @include mq(ss){
    font-size: 1.125em;
    &__content{
      padding: 1.5em 1em 1.25em;
    }
  }
}

/*-------------------------------
  PANEL > REQUIREMENT
-------------------------------*/
.p-panel_requirement{
  $amp: &;
  &__head{
    margin-bottom: 2em;
  }
  &__title{
    position: relative;
    background: #699d80;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    padding: 1.15em 3em 1.15em 2em;
    font-weight: 500;
    cursor: pointer;
    &:before,
    &:after{
      position: absolute;
      right: 2em;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before{
      font-size: 1.165em;
      content: "\02b";
    }
    &.is-act{
      &:before{
        font-size: 1em;
        content: "\02193";
      }
    }
    &.-finished{
      background: #b2b2b2;
      padding-right: 2em;
      cursor: auto;
      &:before,
      &:after{
        display: none;
      }
    }
  }
  &__name{
    width: 30%;
  }
  &__count{
    width: 50%;
  }
  &__toggle{
    text-align: right;
    width: 20%;
  }
  &__body{
    display: none;
    padding-top: 2em;
  }
  &__subttl{
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1;
    border-bottom:solid .5rem $main_color;
    padding-bottom: .5em;
    margin-bottom: 1.5em;
  }
  &__box{
    &:nth-child(n+2){
      padding: $margin_lg 0;
    }
  }
  &__leading{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    figure{
      width: calc(50% - #{$margin_md});
    }
    aside{
      width: 50%;
      font-size: 1.2em;
      line-height: 1.4;
      > p{
        &:nth-child(n+2){
          margin-top: 1em;
        }
      }
    }
  }
  &__sheet{
    margin-top: $margin_lg;
    th,
    td{
      padding: .75em 1em;
      font-size: 1.25em;
      vertical-align: top;
      line-height: 1.4;
      font-weight: 400;
      text-align: left;
    }
    th{
      width: 20%;
    }
    td{
      width: 80%;
    }
  }
  @include mq(lg){
    &__title{
      padding: 1.15em 2.5em 1.15em 1.5em;
      &.-finished{
        padding-right: 1.5em;
      }
      &:before,
      &:after{
        right: 1.5em;
      }
    }
    &__subttl{
      font-size: 2.2em;
    }
    &__leading{
      figure{
        width: 40%;
      }
      aside{
        width: 55%;
      }
    }
  }
  @include mq(md){
    &__sheet{
      margin-top: $margin_md;
    }
  }
  @include mq(sm){
    &__name{
      width: 40%;
    }
    &__count{
      width: 30%;
    }
    &__toggle{
      text-align: right;
      width: 30%;
    }
    &__sheet{
      th,
      td{
        font-size: 1.125em;
      }
    }
  }
  @include mq(xs){
    &__title{
      display: block;
      padding: 1.125em 2em 1.125em 1em;
      &:before{
        right: 1em;
      }
      &.-finished{
        #{$amp}__count{
          display: none;
        }
      }
    }
    &__name,
    &__count,
    &__toggle{
      width: 100%;
    }
    &__name{
      margin-bottom: .5em;
    }
    &__toggle{
      text-align: left;
    }
    &__subttl{
      font-size: 2em;
      border-bottom-width: .375rem;
    }
    &__leading{
      display: block;
      figure{
        width: 100%;
        margin: 0 auto $margin_md;
      }
      aside{
        width: 100%;
      }
      @include mq(ss-){
        figure{
          width: 75%;
        }
      }
    }
    &__sheet{
      tr{
        display: block;
        &:nth-child(n+2){
          padding-top: 1.5em;
          margin-top: 1.5em;
          border-top:solid 1px #b2b2b2;
        }
      }
      th,
      td{
        display: block;
        width: 100%;
        padding: 0;
      }
      th{
        font-weight: 500;
        margin-bottom: .5em;
        &:before{
          content: "■";
          color: $main_color;
          margin-right: .5em;
        }
      }
    }
  }
}
