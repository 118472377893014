@charset "utf-8";
/*--------------------------------------------
  LOADING
--------------------------------------------*/
.c-loading{
  $amp :&;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  &__wrap{
    width: 100%;
    height: 100%;
    background: url('../img/common/loading_bg.png') no-repeat center center / cover;
    opacity: 0;
    transition: opacity .75s;
  }
  &__img{
    width: 100%;
    height: 100%;
    background: url('../img/common/loading_img.png') no-repeat center center / contain;
    opacity: 0;
    transition: opacity 1s;
    transition-delay: .75s;
  }
  &.is-loading{
    #{$amp}__wrap{
      opacity: 1;
    }
    #{$amp}__img{
      opacity: 1;
    }
  }
}
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
@keyframes fadezoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes fadezoomOut {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ripple{
  0%{
    opacity: 1;
    width: 50;
    padding-top: 50;
  }
  16.66666%{
    opacity: 0;
    width: 150%;
    padding-top: 150%;
  }
  100%{
    opacity: 0;
    width: 50%;
    padding-top: 50%;
  }
}
@keyframes draw_path {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 1;
    fill-opacity:0;
  }

  75% {
    stroke-dashoffset: 0;
    stroke-width: 1;
    fill-opacity: 0;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill-opacity: 1;
  }
}
@keyframes hover_light {
  100% {
    outline-color: transparent;
    outline-offset: .75em;
  }
}
/*--------------------------------------------
  MIXIN
--------------------------------------------*/
@mixin animation($type: 'fadeUp',$status: 'before'){
  @if $type == 'fadeUp' {
    @if $status == 'before' {
      transition-property:transform,opacity;
      transform:translateY($margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateY(0);
      opacity: 1;
    }
  }
  @else if $type == 'fadeLeft' {
    @if $status == 'before' {
      transition-property: transform, opacity;
      transform:translateX($margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateX(0);
      opacity: 1;
    }
  }

  @else if $type == 'fadeRight' {
    @if $status == 'before' {
      transition-property: transform, opacity;
      transform:translateX(-$margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateX(0);
      opacity: 1;
    }
  }

  @else if $type == 'fadeIn' {
    @if $status == 'before' {
      transition-property: opacity;
      opacity: 0;
    }@else if $status == 'after' {
      opacity: 1;
    }
  }

  @else if $type == 'scaleUp' {
    @if $status == 'before' {
      transition-property: transform;
      transform:scale(0);
    }@else if $status == 'after' {
      transform:scale(1);
    }
  }
}
