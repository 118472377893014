@charset "utf-8";
/*============================================

  SERVICE

============================================*/
/*-------------------------------
  SERVICE > POLICY
-------------------------------*/
.p-service_policy{
  @include mq(screen){
    @include animation('fadeIn');
    transition:.5s;
    @include mq(sm-){
      transition-delay:2.5s;
    }
    @include mq(sm){
      transition-delay:1s;
    }
    &.is-anime{
      @include animation('fadeIn', 'after');
    }
  }
}
/*-------------------------------
  SERVICE > DETAIL
-------------------------------*/
.p-service_detail{
  $amp: &;
  padding: $margin_sm*2 0;
  &__item{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4rem;
  }
  &__txt{
    width: 33%;
  }
  &__img{
    width: 64%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > *{
      width: 48%;
    }
  }
  @include mq(sm){
    &__txt{
      width: 40%;
    }
    &__img{
      width: 55%;
    }
  }
  @include mq(xs){
    &__txt{
      width: 60%;
    }
    &__img{
      width: 35%;
      @include mq(ss-){
        display: block;
        > *{
          width: 100%;
          &:nth-child(n+2){
            margin-top: 1em;
          }
        }
      }
    }
  }
  @include mq(ss){
    &__item{
      display: block;
      margin-top: 3rem;
    }
    &__txt{
      width: 100%;
    }
    &__img{
      width: 100%;
      margin-top: 1.5em;
    }
  }
  @include mq(screen){
    &__ttl{
      transition:.5s;
      @include animation('fadeLeft');
    }
    &.is-anime{
      #{$amp}__ttl{
        @include animation('fadeLeft', 'after');
      }
    }
    @include mq(ss-){
      &__txt,
      &__img{
        transition:.5s;
        @include animation('fadeLeft');
      }
      &__img{
        transition-delay:.5s;
      }
      &__item{
        &.is-anime{
          #{$amp}__txt,
          #{$amp}__img{
            @include animation('fadeLeft', 'after');
          }
        }
      }
    }
    @include mq(ss){
      &__item{
        transition:.5s;
        @include animation('fadeUp');
        &.is-anime{
          @include animation('fadeUp', 'after');
        }
      }
    }
  }
}
