@charset "utf-8";
/*============================================

  NEWS

============================================*/
/*-------------------------------
  NEWS > COMMON
-------------------------------*/
.p-news{
  &__head{
    border-bottom:solid .5rem $main_color;
    padding-bottom: 1em;
    margin-bottom: 2.5em;
    time{
      font-size: 1.25em;
    }
    h1{
      font-size: 2.25em;
      line-height: 1.4;
    }
  }
  &__foot{
    margin-top: $margin_ll;
    padding: $margin_ll 0;
    border-top:solid 1px #b2b2b2;
    text-align: center;
  }
  @include mq(lg){
    &__head{
      h1{
        font-size: 2em;
      }
    }
  }
  @include mq(xs){
    &__head{
      h1{
        font-size: 1.75em;
      }
    }
    &__foot{
      margin-top: $margin_md;
      padding: $margin_md 0;
    }
  }
  @include mq(ss){
    &__head{
      time{
        font-size: 1em;
      }
      h1{
        font-size: 1.5em;
      }
    }
  }
  @include mq(xs){
    &__head{
      border-bottom-width: .375rem;
    }
  }
}
