@charset "utf-8";

/*============================================

  COMPANY

============================================*/
/*-------------------------------
  COMPANY > COMMON
-------------------------------*/
.p-company {
  &__block {
    padding-bottom: $margin_md*3;

    @include mq(sm) {
      padding-bottom: $margin_md*2;
    }

    @include mq(ss) {
      padding-bottom: $margin_lg;
    }
  }
}

/*-------------------------------
  COMPANY > IDENTITY
-------------------------------*/
.p-company_identity {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  $amp: &;

  @include mq(md) {
    &__txt {
      p {
        font-size: 1.3em;
      }
    }
  }

  @include mq(sm) {
    &__ttl {
      margin-bottom: 2.5em;
    }

    &__img {
      width: 40%;
    }

    &__txt {
      width: 55%;
    }
  }

  @include mq(xs) {
    display: block;

    &__img {
      width: 100%;
    }

    &__txt {
      width: 100%;
      margin-top: 2em;

      p {
        font-size: 1.2em;
      }
    }
  }

  @include mq(screen) {

    &__ttl,
    &__img,
    &__txt {
      transition: .5s;

      @include animation("fadeLeft");
    }

    &__img {
      transition-delay: .5s;
    }

    &__txt {
      transition-delay: 1s;
    }

    &.is-anime {

      #{$amp}__ttl,
      #{$amp}__img,
      #{$amp}__txt {
        @include animation("fadeLeft", "after");
      }
    }
  }

  &__ttl {
    width: 100%;
    margin-bottom: 4em;
  }

  &__img,
  &__txt {
    width: calc(50% - 2em);

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__txt {
    p {
      font-size: 1.4em;
      line-height: 2;
      text-indent: -.5em;

      @media screen and (max-width: 767px) {
        font-size: 1.2em;

        br {
          display: none;
        }
      }
    }
  }
}

/*-------------------------------
  COMPANY > MESSAGE
-------------------------------*/
.p-company_message {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  $amp: &;

  @include mq(sm) {
    &__ttl {
      margin-bottom: 2.5em;
    }

    &_ &__img {
      width: 40%;
    }

    &__txt {
      width: 55%;
    }
  }

  @include mq(xs) {
    display: block;

    &__img {
      width: 100%;
    }

    &__txt {
      width: 100%;
      margin-top: 2em;
    }
  }

  @include mq(screen) {

    &__ttl,
    &__img,
    &__txt {
      transition: .4s;

      @include animation("fadeLeft");
    }

    &__txt {
      transition-delay: .2s;
    }

    &__img {
      transition-delay: .4s;
    }

    &.is-anime {

      #{$amp}__ttl,
      #{$amp}__img,
      #{$amp}__txt {
        @include animation("fadeLeft", "after");
      }
    }
  }

  &__ttl {
    order: 1;
    width: 100%;
    margin-bottom: 4em;
  }

  &__img,
  &__txt {
    width: calc(50% - 2em);

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__txt {
    order: 2;

    p {
      font-size: 1.1em;
      line-height: 2;
    }

    aside {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 2em;

      span {
        margin-right: 1.5em;
        font-weight: 400;
        font-size: 1.1em;
      }

      em {
        font-style: normal;
        font-size: 1.2em;
      }
    }
  }

  &__img {
    order: 3;
  }
}

/*-------------------------------
  COMPANY > PROFILE
-------------------------------*/
.p-company_profile {
  @include mq(md) {
    &__sheet {
      padding: 0;

      th,
      td {
        padding: .75em 0;
        font-size: 1.3em;
      }
    }
  }

  @include mq(xs) {
    &__sheet {

      th,
      td {
        font-size: 1.2em;
      }

      th {
        width: 30%;
      }
    }
  }

  @include mq(ss) {
    &__sheet {
      tr {
        display: block;

        &:nth-child(n+2) {
          margin-top: 1.5em;
          padding-top: 1.5em;
          border-top: solid 1px #b2b2b2;
        }
      }

      th,
      td {
        display: block;
        padding: 0;
        font-size: 1.15em;
      }

      th {
        width: 100%;
        margin-bottom: .5em;

        &:before {
          margin-right: .5em;
          color: $main_color;
          content: "■";
        }
      }
    }
  }

  @include mq(screen) {
    transition: .4s;

    @include animation("fadeUp");

    &.is-anime {
      @include animation("fadeUp", "after");
    }
  }

  &__sheet {
    padding: 0 5em;

    @media screen and (max-width: 1199px) {
      padding: 0;
    }

    th,
    td {
      padding: 1em 0;
      font-weight: 400;
      font-size: 1.5em;
      line-height: 1.666;
      text-align: left;
      vertical-align: top;

      @media screen and (max-width: 767px) {
        font-size: 1.2em;
      }

      @media screen and (max-width: 575px) {
        padding: 0;
        font-size: 1.3em;
      }
    }

    th {
      width: 20%;

      @media screen and (max-width: 575px) {
        width: 100%;
        padding: 0;
      }
    }
  }
}

/*-------------------------------
  COMPANY > CHART
-------------------------------*/
.p-company_chart {
  @include mq(screen) {
    transition: .4s;

    @include animation("fadeUp");

    &.is-anime {
      @include animation("fadeUp", "after");
    }
  }
}

/*-------------------------------
  COMPANY > ACCESS
-------------------------------*/
.p-company_access {
  @include mq(screen) {
    &__block {
      transition: .4s;

      @include animation("fadeUp");

      &.is-anime {
        @include animation("fadeUp", "after");
      }
    }
  }

  @include mq(md) {
    &__block {
      padding: 0;
    }

    &__ttl,
    &__txt {
      font-size: 1.3em;
    }
  }

  @include mq(xs) {
    &__block {
      &:nth-last-child(n+2) {
        margin-bottom: $margin_lg;
      }
    }

    &__ttl,
    &__txt {
      font-size: 1.2em;
    }

    &__map {
      margin-top: 1.5em;
      padding-top: 50%;
    }
  }

  @include mq(ss) {
    &__block {
      display: block;
    }

    &__ttl,
    &__txt {
      width: 100%;
    }

    &__ttl {
      margin-bottom: .75em;
    }

    &__txt {
      font-size: 1.15em;
    }

    &__map {
      padding-top: 125%;
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 5em;

    @media screen and (max-width: 1199px) {
      padding: 0;
    }

    &:nth-last-child(n+2) {
      margin-bottom: $margin_md*2;

      @media screen and (max-width: 767px) {
        margin-bottom: 3.75rem;
      }
    }
  }

  &__ttl,
  &__txt {
    font-size: 1.5em;
    line-height: 1.333;

    @media screen and (max-width: 1199px) {
      font-size: 1.3em;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.2em;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      margin-bottom: .75em;
    }
  }

  &__ttl {
    width: 30%;
    font-weight: 500;

    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  &__txt {
    width: 70%;
    font-weight: 400;

    @media screen and (max-width: 575px) {
      width: 100%;
      margin-bottom: 0;
      font-size: 1.15em;
    }
  }

  &__map {
    position: relative;
    width: 100%;
    margin-top: 2.5em;
    padding-top: 33%;

    @media screen and (max-width: 767px) {
      margin-top: 1.5em;
      padding-top: 50%;
    }

    @media screen and (max-width: 575px) {
      padding-top: 125%;
    }
  }
}

/*-------------------------------
  COMPANY > HISTORY
-------------------------------*/
.p-company_history {
  @include mq(md) {
    &__sheet {
      padding: 0;

      th,
      td {
        font-size: 1.3em;
      }
    }
  }

  @include mq(xs) {
    &__sheet {

      th,
      td {
        font-size: 1.2em;
      }

      th {
        width: 35%;
      }
    }
  }

  @include mq(ss) {
    &__sheet {
      tr {
        display: block;

        &:nth-child(n+2) {
          margin-top: 1.5em;
          padding-top: 1.5em;
          border-top: solid 1px #b2b2b2;
        }
      }

      th,
      td {
        display: block;
        padding: 0;
        font-size: 1.15em;
      }

      th {
        width: 100%;
        margin-bottom: .5em;

        >* {
          display: inline;
        }

        &:before {
          margin-right: .5em;
          color: $main_color;
          content: "■";
        }
      }

      td {
        font-size: 1em;
      }
    }
  }

  @include mq(screen) {
    transition: .4s;

    @include animation("fadeUp");

    &.is-anime {
      @include animation("fadeUp", "after");
    }
  }

  &__sheet {
    padding: 0 5em;

    @media screen and (max-width: 1199px) {
      padding: 0;
      ;
    }

    th,
    td {
      padding: 1em 0;
      font-weight: 400;
      font-size: 1.5em;
      line-height: 1.666;
      text-align: left;
      vertical-align: top;

      @media screen and (max-width: 1199px) {
        font-size: 1.2em;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.3em;
      }

      @media screen and (max-width: 575px) {
        display: block;
        padding: 0;
        font-size: 1em;
      }
    }

    th {
      width: 30%;

      @media screen and (max-width: 768px) {
        width: 35%;
      }

      @media screen and (max-width: 575px) {
        display: block;
        display: flex;
        width: 100%;
        margin-bottom: .5em;
        padding: 0;
        font-size: 1.15em;
      }

      >* {
        display: block;
      }

      em {
        font-style: normal;
      }

      span {
        &:before {
          content: "(";
        }

        &:after {
          content: ")";
        }
      }
    }
  }
}