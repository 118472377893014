@charset "utf-8";
/*============================================

  FOOTER

============================================*/
.l-footer{
  &__top{
    background: url('../img/common/bg_footer.jpg') no-repeat center center / cover;
    color: #fff;
    line-height: 1;
    font-size: 3em;
    text-align: center;
    font-weight: 700;
    padding: 7em 0 1em;
  }
  &__bottom{
    background: $main_color;
    padding: 2em 0 2.75em;
    color: #fff;
  }
  &__info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__logo{
    svg{
      width: 8.7em;
      height: 2.15em;
    }
  }
  &__address{
    font-size: .7em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 2em;
    dt{
      line-height: 1.4;
      width: 100%;
      font-weight: 400;
    }
    dd{
      line-height: 1.4;
      font-weight: 400;
      &:nth-last-child(n+2){
        padding-right: 4em;
      }
    }
  }
  &__conclusion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    font-size: .6em;
    margin-top: 2em;
  }
  &__notes{
    font-weight: 400;
  }
  &__copy{
    font-weight: 500;
  }
  @include mq(md){
    &__top{
      font-size: 2.5em;
      padding: 5em 0 1em;
    }
    &__info{
      display: block;
    }
    &__address{
      font-size: .875em;
      margin-left: 0;
      margin-top: 1em;
      justify-content: space-between;
      dt{
        width: 100%;
      }
      dd{
        width: 30%;
        &:nth-last-child(n+2){
          padding-right: 0;
        }
      }
    }
    &__conclusion{
      font-size: .75em;
    }
  }
  @include mq(sm){
    &__address{
      dd{
        width: 31%;
      }
    }

    &__conclusion{
      display: block;
      font-size: .875em;
    }
    &__copy{
      margin-top: .75em;
    }
  }
  @include mq(xs){
    &__top{
      font-size: 2em;
      padding: 2em 0 6em;
    }
    &__logo{
      text-align: center;
    }
    &__address{
      display: block;
      dt{
        display: none;
      }
      dd{
        width: 100%;
        text-align: center;
        &:nth-last-child(n+2){
          margin-bottom: 1em;
        }
      }
    }
    &__conclusion{
      text-align: center;
    }
  }
  @include mq(ss){
    &__top{
      font-size: 1.5em;
    }
  }
}
