@charset "utf-8";

/*============================================

  HOME

============================================*/
/*-------------------------------
  HOME > ABOUT
-------------------------------*/
.p-home_about {
  position: relative;

  @include mq(lg) {
    &__img {
      right: -5rem;
      width: calc(50% + 5rem);

      img {
        transform: translateX(0);
        width: calc(50% + 17rem);
        padding-left: 1rem;

        @media screen and (max-width: 1200px) {
          width: calc(50% + 14rem);
        }

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }

  @include mq(md) {
    &__ttl {
      font-size: 2.2em;
    }
  }

  @include mq(sm) {
    &__txt {
      width: 100%;
    }
  }

  @include mq(ss) {
    &__ttl {
      font-size: 1.625em;
    }
  }

  @include mq(screen) {
    @include mq(sm-) {

      &__txt,
      &__img {
        transition: .5s;

        @include animation("fadeLeft");
      }

      &__txt {
        transition-delay: 1.5s;
      }

      &__img {
        transition-delay: 2s;
      }

      .is-anime & {

        &__txt,
        &__img {
          @include animation("fadeLeft", "after");
        }
      }
    }

    @include mq(sm) {
      &__txt {
        transition: .5s;

        @include animation("fadeUp");
      }

      &__txt {
        transition-delay: 1s;
      }

      .is-anime & {

        &__txt,
        &__img {
          @include animation("fadeUp", "after");
        }
      }
    }
  }

  &__txt {
    width: 50%;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    >p {
      line-height: 2;
      text-indent: 1em;
    }
  }

  &__video {
    margin-bottom: 1.5em;
  }

  &__img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: -10rem;
    width: calc(50% + 10rem);
    height: 100%;

    img {
      // transform: translateX(5rem);
    }
  }

  &__ttl {
    margin-bottom: .75em;
    font-size: 2.8em;

    @media screen and (max-width: 575px) {
      font-size: 1.625em;
    }
  }
}

/*-------------------------------
  HOME > DIVISION
-------------------------------*/
.p-home_division {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  $amp: &;

  @include mq(screen) {
    &__block {
      transition: .5s;

      @include animation("fadeUp");

      &:nth-child(1) {
        transition-delay: 1.2s;
      }

      &:nth-child(2) {
        transition-delay: 1.7s;
      }
    }

    .is-anime & {
      &__block {
        @include animation("fadeUp", "after");
      }
    }
  }

  @include mq(md) {
    &__ttl {
      em {
        font-size: 2em;
      }
    }
  }

  @include mq(xs) {
    display: block;

    @include mq(ss-) {
      &__link {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      &__img,
      &__ttl {
        width: 50%;
        margin-bottom: 0;
      }

      &__ttl {
        order: 1;
        text-align: left;
      }

      &__img {
        order: 2;
      }

      &__txt {
        order: 3;
        width: 100%;
        margin-top: 1.25em;
      }
    }

    &__block {
      width: 100%;

      &:nth-child(n+2) {
        margin-top: $margin_lg;
      }
    }
  }

  @include mq(ss) {
    &__img {
      margin-bottom: 1.25em;
    }

    &__ttl {
      margin-bottom: 1em;
      font-size: 1.25em;
    }
  }

  &__block {
    width: 48%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__link {
    display: block;

    @include mq(sm-) {
      &:hover {
        #{$amp}__img {
          img {
            opacity: .5;
          }
        }

        #{$amp}__ttl,
        #{$amp}__txt {
          opacity: .5;
        }
      }
    }
  }

  &__img {
    margin-bottom: 2em;
    background: #ffffff;
    text-align: center;

    @media screen and (max-width: 575px) {
      margin-bottom: 1.25em;
    }

    img {
      width: 100%;
      transition: opacity .4s;
    }
  }

  &__ttl {
    margin-bottom: 1.5em;
    font-size: 1.5em;
    text-align: center;
    transition: opacity .4s;

    @media screen and (max-width: 575px) {
      margin-bottom: 1em;
      font-size: 1.25em;
    }

    >* {
      display: block;
    }

    em {
      color: $main_color;
      font-style: normal;
      font-size: 2.5em;
      font-family: $base_font_en;

      @media screen and (max-width: 1199px) {
        font-size: 2em;
      }
    }

    span {
      margin-top: .75em;
    }
  }

  &__txt {
    transition: opacity .4s;
  }
}

/*-------------------------------
  HOME > NEWS
-------------------------------*/
.p-home_news {
  @include mq(screen) {

    &__list,
    &__btn {
      transition: .5s;

      @include animation("fadeUp");
    }

    &__list {
      transition-delay: 1.2s;
    }

    &__btn {
      transition-delay: 1.7s;
    }

    .is-anime & {

      &__list,
      &__btn {
        @include animation("fadeUp", "after");
      }
    }
  }

  &__list {
    margin-bottom: $margin_lg;
  }

  &__btn {
    text-align: center;
  }
}