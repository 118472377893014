@charset "utf-8";
/*============================================

  CONTAINER

============================================*/
.c-inner{
  width: 100%;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10rem;
  @include mq(lg){
    padding: 0 4%;
  }
}
/*============================================

  WRAP

============================================*/
.c-boxpd{
  padding: $margin_md*2 0;
  &_xl{
    padding: $margin_md*3 0;
  }
  &_ll{
    padding: $margin_ll 0;
  }
  &_lg{
    padding: $margin_lg 0;
  }
  &_md{
    padding: $margin_md 0;
  }
  &_sm{
    padding: $margin_sm 0;
  }
  &_ss{
    padding: $margin_ss 0;
  }
  @include mq(sm){
    padding: $margin_lg 0;
    &_xl{
      padding: $margin_md*2 0;
    }
    &_ll{
      padding: $margin_sm*3 0;
    }
    &_lg{
      padding: $margin_md 0;
    }
    &_md{
      padding: $margin_sm 0;
    }
    &_sm{
      padding: $margin_ss 0;
    }
    &_ss{
      padding: $margin_ss/2 0;
    }
  }
}
/*============================================

  LEADING

============================================*/
.c-leading{
  border-top:solid 1px #e5e5e5;
  border-bottom:solid 1px #e5e5e5;
  padding: $margin_sm 0;
}
/*============================================

  IFRAME

============================================*/
.c-iframe{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/*============================================

  GOOGLE MAP

============================================*/
.c-gmap{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/*============================================

  COLUMN

============================================*/
.c-flex_container{
  overflow: hidden;
}
.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  @include mq(ss-){
  }
  @include mq(xs-sm){
  }
  @include mq(ss-xs){
  }
  @include mq(ss){
  }
}
