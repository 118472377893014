@charset "utf-8";
/*============================================

  CONTACT

============================================*/
/*-------------------------------
  NEWS > COMMON
-------------------------------*/
.p-contact{
  padding-bottom: $margin_ll*2;
  &__form{
    margin-top: $margin_md*4;
  }
  @include mq(sm){
    padding-bottom: $margin_ll;
    &__form{
      margin-top: $margin_md*2;
    }

  }
}
