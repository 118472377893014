@charset "utf-8";
/*============================================

  KV

============================================*/
/*-------------------------------
  KV > TOP
-------------------------------*/
.p-kv{
  $amp: &;
  &__slider{
    margin-bottom: 0;
  }
  &__img{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: scale(1);
  }
  &__txt{
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 2;
    object{
      filter:  drop-shadow(0 0 6px #000);
      max-width: 100%;
    }
  }
  &__slide{
    position: relative;
    overflow: hidden;
    height: 50vw;
    &.-slide01{
      #{$amp}__txt{
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
        object{
          width: 50.1rem;
          height: 14rem;
        }
      }
    }
    &.-slide02{
      #{$amp}__txt{
        top: 12.5%;
        text-align: right;
        object{
          width: 50.4rem;
          height: 20.5rem;
        }
      }
    }
    &.-slide03{
      #{$amp}__txt{
        top: 12.5%;
        text-align: center;
        object{
          width: 61rem;
          height: 13.6rem;
        }
      }
    }
  }
  @include mq(screen){
    &__slide{
      &.-slide01{
        .slick-slide &{
          #{$amp}__txt{
            opacity: 0;
            transform: translateX(10rem);
            transition:2s ease-out .5s;
          }
        }
        .slick-slide.is-anime &{
          #{$amp}__img{
            img{
              transform-origin: right top;
              animation: fadezoomOut 8s 0s ease-out forwards;
            }
          }
          #{$amp}__txt{
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      &.-slide02{
        .slick-slide &{
          #{$amp}__txt{
            opacity: 0;
            transform: translateX(10rem);
            transition:2s ease-out .5s;
          }
        }
        .slick-slide.is-anime &{
          #{$amp}__img{
            img{
              transform-origin: left top;
              animation: fadezoom 8s 0s ease-out forwards;
            }
          }
          #{$amp}__txt{
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      &.-slide03{
        .slick-slide &{
          #{$amp}__txt{
            opacity: 0;
            transform: translateY(5rem);
            transition:2s ease-out .5s;
          }
        }
        .slick-slide.is-anime &{
          #{$amp}__img{
            img{
              transform-origin: center center;
              animation: fadezoom 8s 0s ease-out forwards;
            }
          }
          #{$amp}__txt{
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }
  @include mq(lg){
    &__slide{
      &.-slide01{
        #{$amp}__txt{
          object{
            width: 43.8375rem;
            height: 12.25rem;
          }
        }
      }
      &.-slide02{
        #{$amp}__txt{
          object{
            width: 44.1rem;
            height: 17.9375rem;
          }
        }
      }
      &.-slide03{
        #{$amp}__txt{
          object{
            width: 52.5rem;
            height: 11.9rem;
          }
        }
      }
    }
  }
  @include mq(md){
    &__slide{
      &.-slide01{
        #{$amp}__txt{
          object{
            width: 37.575rem;
            height: 10.5rem;
          }
        }
      }
      &.-slide02{
        #{$amp}__txt{
          object{
            width: 37.8rem;
            height: 15.375rem;
          }
        }
      }
      &.-slide03{
        #{$amp}__txt{
          object{
            width: 45.75rem;
            height: 10.2rem;
          }
        }
      }
    }
  }
  @include mq(sm){
    &__slide{
      height: 62.5vw;
      &.-slide01{
        #{$amp}__txt{
          object{
            width: 37.575rem;
            height: 10.5rem;
          }
        }
      }
      &.-slide02{
        #{$amp}__txt{
          object{
            width: 37.8rem;
            height: 15.375rem;
          }
        }
      }
      &.-slide03{
        #{$amp}__txt{
          object{
            width: 45.75rem;
            height: 10.2rem;
          }
        }
      }
    }
  }
  @include mq(xs){
    &__slide{
      &.-slide01{
        #{$amp}__txt{
          top: initial;
          bottom: 10%;
          object{
            width: 31.3125rem;
            height: 8.75rem;
          }
        }
      }
      &.-slide02{
        #{$amp}__txt{
          top: initial;
          bottom: 10%;
          object{
            width: 31.5rem;
            height: 12.8125rem;
          }
        }
      }
      &.-slide03{
        #{$amp}__txt{
          object{
            width: 38.125rem;
            height: 8.5rem;
          }
        }
      }
    }
  }
  @include mq(ss){
    &__slide{
      height: 87.5vw;
      &.-slide01{
        #{$amp}__txt{
          object{
            width: 18.7875rem;
            height: 5.25rem;
          }
        }
      }
      &.-slide02{
        #{$amp}__txt{
          object{
            width: 19.026rem;
            height: 7.5375rem;
          }
        }
      }
      &.-slide03{
        #{$amp}__txt{
          object{
            width: 22.5375rem;
            height: 5.1rem;
          }
        }
      }
    }
  }
}

/*-------------------------------
  KV > UNDER
-------------------------------*/
.p-kv_under{
  position: relative;
  width: 100%;
  height: 15rem;
  @include mq(lg){
    height: 17.5rem;
  }
  @include mq(ss){
    height: 10rem;
  }
}
